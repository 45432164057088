// import * as firebase from "firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

const config = {
  apiKey: "AIzaSyD_IWwxH9dIkEw8kIXs8r2qJrBHCJEUZo4",
  authDomain: "checkdx.firebaseapp.com",
  databaseURL:
    "https://checkdx-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "checkdx",
  storageBucket: "checkdx.appspot.com",
  messagingSenderId: "956194559436",
  appId: "1:956194559436:web:29e6e95ba6325053deb969",
};
firebase.initializeApp(config);
firebase.firestore();
firebase.functions();
firebase.analytics();

// firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
